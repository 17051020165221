export interface SidebarNavState {
	isSidebarCollapse: boolean;
}

export enum UserlistTabs {
	CLIENTS = "clients",
	COLLEAGUES = "colleagues",
}

/**
 * This type will adapt to any number of tabs specified in UserlistTabs :)
 */
export interface TabbedUserListTranslations {
	searchPlaceholders: {[key in UserlistTabs]: string};
	tabHeaders: {[key in UserlistTabs]: string};
}

/**
 * Max unread messages for contact list, user tab ad chat box
 */
export enum MAX_UNREAD_CHAT {
	CONTACT_LIST = 99,
	USER_TAB = 999,
	CHAT_BOX = 99,
}

/**
 * Sidebar and Top navigation main menu breakpoint
 */
export const MAIN_MENU_BREAKPOINT = 830;
