import {eventChannel} from "redux-saga";
import {fork, take, put, call, cancelled, select} from "redux-saga/effects";

import analyticsService from "../../analytics/AnalyticsService";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {createSendMessage, subscribeToInterWindowCommunication} from "../../IWC/IWC";
import {WinMsg, WinMsgTypes} from "../../IWC/WinMsg";
import createLogger from "../../logger/createLogger";
import {agoraActions} from "../redux/agoraActions";
import {AgoraWindowAction} from "../redux/types";
import {getAgoraRoomId, getAgoraWindowAction, isJoinWithVideo} from "../redux/agoraSelectors";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {isVideoCall} from "../../startCallScreen/redux/startCallScreenSelector";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";

const log = createLogger("agoraWindowMsgSaga", SentryTags.AgoraMeeting);

function* createIncomingAgoraWindowMsgChannel(targetWindow: Window) {
	return eventChannel((emitter) => {
		const unsubscribe = subscribeToInterWindowCommunication({
			targetWindow,
			onMessage: emitter,
		});

		return unsubscribe;
	});
}

/**
 * This function will be executed when call window is ready to receive IWC messages from the main window.
 */
function* syncAgoraWindowData(agoraWindow: Window) {
	log.debug("syncAgoraWindowData");

	const sendIWCMessage = yield call(createSendMessage, agoraWindow);
	const authUser: AuthUser = yield select(getAuthUser);
	const selectedContactId: number = yield select(getSelectedContactId);
	const windowAction: AgoraWindowAction = yield select(getAgoraWindowAction);
	const roomId: string = yield select(getAgoraRoomId);
	const isVideoEnabled: boolean = yield select(isVideoCall);
	const isJoinAgoraWithVideo: boolean = yield select(isJoinWithVideo);

	// Send analytics instance id to the call window so that we can initialize the analyticsService in the call window with the same ID.
	yield call(sendIWCMessage, {
		type: WinMsgTypes.SHARE_PARENT_ANALYTICS_ID,
		data: {
			parentInstanceID: analyticsService.getInstanceID(),
			agoraWindowAction: windowAction,
			roomId,
			authUser,
			remoteUserId: selectedContactId,
			isVideoEnabled: isJoinAgoraWithVideo || isVideoEnabled,
		},
	});
}

function* incomingWindowMsgHandler(agoraWindow: Window, msg: WinMsg) {
	log.debug("incoming iwc data", msg);

	const windowAction: AgoraWindowAction = yield select(getAgoraWindowAction);

	switch (msg.type) {
		case WinMsgTypes.AGORA_WINDOW_READY:
			// Synchronize Agora window data from the main window by sending them via IWC
			yield fork(syncAgoraWindowData, agoraWindow);
			break;

		case WinMsgTypes.PRODUCT_FRUITS_CHECK:
			// Check for product fruits data props
			yield call(sendProductFruitsProps, agoraWindow);
			break;

		case WinMsgTypes.AGORA_CREATED_MEETING_LINK:
			if (windowAction === AgoraWindowAction.CREATE) {
				// TODO remove this condition after reconstruct agora app builder messaging event
				const createdLink: string = msg.data?.value?.link.toString();
				yield put(agoraActions.userCreatedMeeting(createdLink));
			}
			break;

		case WinMsgTypes.AGORA_JOIN_MEETING:
			yield put(agoraActions.userJoinedMeeting(Date.now()));
			// Add joined time to local storage for countinous analytics purpose
			storage.write(StorageKeys.AGORA_JOINED_MEETING, {joinedTime: Date.now()});
			break;

		case WinMsgTypes.AGORA_LEAVE_MEETING:
			yield put(agoraActions.userLeaveMeeting(Date.now()));
			// Simply close the Agora window when user leave meeting
			yield put(agoraActions.closeAgoraWindow());
			break;
	}
}

export function* onIncomingWindowMsgSaga(agoraWindow: Window) {
	const incomingCallWindowMsgChannel = yield call(createIncomingAgoraWindowMsgChannel, agoraWindow);
	try {
		while (true) {
			const msg: WinMsg = yield take(incomingCallWindowMsgChannel);
			yield fork(incomingWindowMsgHandler, agoraWindow, msg);
		}
	} finally {
		if (yield cancelled()) {
			incomingCallWindowMsgChannel.close();
		}
	}
}

/**
 * This function will send data for product fruits props
 */
function* sendProductFruitsProps(callWindow: Window) {
	log.debug("sendProductFruitsProps");

	const authUser = yield select(getAuthUser);
	const sendIWCMessage = yield call(createSendMessage, callWindow);

	// Send authUser data to call window
	yield call(sendIWCMessage, {
		type: WinMsgTypes.PRODUCT_FRUITS_CHECK,
		data: authUser,
	});
}
