import profileSDK from "../../userProfile/helpers/profileSDK";
import {OrganizationMemberStatus, UserOrganization} from "@sense-os/goalie-js";
import {AuthUser, AuthUserRole} from "../authTypes";
import {UserAccountPermissions, UserAccountRole} from "@sense-os/goalie-js/dist/commonTypes";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
import {StorageBools} from "services/system/storage/StorageBools";
import {getAcceptedAndPendingOrganizations} from "../../organizations/helpers/organizationHelper";
import {
	isParnassiaOrgMember,
	havePendingOrgRequest,
	findParnassiaOrgMember,
} from "../../organizations/helpers/organizationHelper";

/**
 * Given token and initial userAccount data (Information gotten from login response)
 * this function will get all the rest of logged in user's data
 *
 * @param {string} token
 * @param {UserAccount} userAccount
 */
export async function getAuthUserData(token: string, userId: number) {
	const userData = await profileSDK.getUserData(token, userId);

	let userOrganization: UserOrganization = null;
	const shouldJoinOrg = storage.read(StorageKeys.SHOULD_JOIN_ORG) === StorageBools.TRUE;
	const externalUserID = storage.read(StorageKeys.EXTERNAL_ID) as string;

	const email = storage.read(StorageKeys.EMAIL) as string;
	const username = storage.read(StorageKeys.USER_NAME) as string;
	const isActive = JSON.parse(storage.read(StorageKeys.IS_ACTIVE_USER) as string);
	const dateJoined = new Date(parseInt(storage.read(StorageKeys.DATE_JOINED) as string));
	const hashId = storage.read(StorageKeys.HASH_ID) as string;
	const role = storage.read(StorageKeys.USER_ROLE) as UserAccountRole;
	const permissions = JSON.parse(storage.read(StorageKeys.USER_PERMISSIONS) as string) as UserAccountPermissions[];

	let userRole = role === UserAccountRole.PROFESSIONAL ? AuthUserRole.THERAPIST : AuthUserRole.CLIENT;

	if (shouldJoinOrg) {
		userRole = AuthUserRole.THERAPIST;
	} else {
		userOrganization = await getAuthUserOrganization(token, userId);
		if (userOrganization) {
			userRole = userOrganization && userRole;
		}
	}

	const isAcceptedToOrg = userOrganization?.status === OrganizationMemberStatus.ACCEPTED;

	const authUser: AuthUser = {
		id: userId,
		externalId: externalUserID,
		hashId,
		fullName: userData.firstName + " " + userData.lastName,
		firstName: userData.firstName,
		lastName: userData.lastName,
		imageUrl: userData.image,
		data: userData,
		account: {
			id: userId,
			email,
			username,
			isActive,
			externalId: externalUserID,
			dateJoined,
			hashId,
			role,
		},
		token: token,
		role: userRole,
		permissions: permissions || [],
		organization: userOrganization
			? {
					id: userOrganization.organization.id,
					name: userOrganization.organization.name,
					isAccepted: isAcceptedToOrg,
					dateJoined: isAcceptedToOrg ? userOrganization.statusUpdatedAt : null,
					logo: userOrganization.organization.logo,
					groupId: userOrganization.organization?.group,
			  }
			: null,
	};

	return authUser;
}

async function getAuthUserOrganization(token: string, userId: number) {
	const userOrg = await getAcceptedAndPendingOrganizations(token, userId);
	if (!userOrg) {
		return null;
	}

	// For Parnassia org member (who have pending request to new org)
	// it should return Parnassia until pending request to new org get accepted.
	// TODO: Remove this when corona scale-up migration id done.
	if (havePendingOrgRequest(userOrg) && isParnassiaOrgMember(userOrg)) {
		return findParnassiaOrgMember(userOrg);
	}

	return userOrg[0];
}
